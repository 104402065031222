/* Manrope Font-Family */
@font-face {
  font-family: 'Manrope';
  src: url('/assets/fonts/Manrope/Manrope-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('/assets/fonts/Manrope/Manrope-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('/assets/fonts/Manrope/Manrope-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('/assets/fonts/Manrope/Manrope-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Manrope';
  src: url('/assets/fonts/Manrope/Manrope-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

/*Thicker Font-Family */
@font-face {
  font-family: 'Thicker';
  src: url('/assets/fonts/Thicker/Thicker-RegularUpright.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Thicker';
  src: url('/assets/fonts/Thicker/Thicker-BoldUpright.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/*Commissioner Font-Family */
@font-face {
  font-family: 'Commissioner';
  src: url('/assets/fonts/Commissioner/Commissioner-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Commissioner';
  src: url('/assets/fonts/Commissioner/Commissioner-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
