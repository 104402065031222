@import "./fonts";

:root {
  /*
  1. Colors
  2. Fonts
  3. Shadows
  4. Backgrounds
  */

  /************* 1. Colors *************/
  --color-dark: #1D1D1D;
  --color-dark-2: #1A1A1A;
  --color-grey-light: #93989E;
  --color-grey: #6C6C6C;
  --color-grey-lighter: #cccccc;
  --color-light-green: #BBFFCC;
  --color-shadow: #F5F5F5;
  --color-border: #E0E0E0;
  --color-white: #FFFFFF;
  --color-red: #FF5151;
  --color-light-red: #FFDBDD;
  --color-green: #1FAD43;
  --color-concrete: #F2F2F2;
  --color-gray-4: #808080;
  --color-gray-5: #666666;
  --color-nobel: #B3B3B3;
  --color-aero-blue: #BBFFCC;
  --color-gray-6: #717171;

  /*** 1.1 offers ***/
  --color-new: #CE90FF;
  --color-good: #79E795;
  --color-poor: #FFBF00;
  --color-fail: #FF4B55;

  /************* 2. Fonts *************/
  --font-primary: 'Manrope', sans-serif;
  --font-secondary: 'Thicker', sans-serif;
  --font-tertiary: 'Commissioner', sans-serif;

  /************* 3. Shadows ***********/
  --shadow-grey: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  --shadow-button: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  --shadow-image: 0 7px 15px 0 rgba(0, 0, 0, 0.1);
  --shadow-modal: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  --shadow-footer: 0 -5px 10px 0 rgba(0, 0, 0, 0.1);
  --shadow-button-secondary: 0 4px 4px rgba(0, 0, 0, 0.1);

  /************* 4. Backgrounds *******/
  --background-opacity: rgba(0, 0, 0, 0.4);
  --background-red: #FF4B55;
  --background-light-green: rgba(187, 255, 204, 0.3);
  --background-grey: #F7F7F7;
  --background-opacity-secondary: rgba(26, 26, 26, 0.7);
  --background-light-brown: #EDDACC;
  --bg-card-1: #E1FFE8;

  /************* 5. Heights **********/
  --vh: 100vh;
}