@import "./variables";
@import "./config-styles";

body, html, * {
  color: var(--color-dark);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--font-primary);
  touch-action: pan-x pan-y;
}

#root {
  position: absolute;
  height: 100%;
  width: 100%;
}

p {
  margin: 0;
}

ol, ul {
  list-style: none;
}

a {
  text-decoration: none;
}

.container {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: calc(6px + env(safe-area-inset-top));
  display: flex;
  min-height: calc(var(--vh) + env(safe-area-inset-top));
  flex-direction: column;
}

.feature-page {
  .button-container {
    margin-bottom: 45px;
  }
}

.top-title {
  margin-top: -24px;
}

.button-container {
  margin-top: auto;
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: column;

  .button-secondary {
    margin-top: 16px;
  }
}

.list-container {
  .list-item {
    margin-bottom: 32px;
  }
}

.image-container {
  display: flex;
  margin: auto;

  img {
    max-height: 38vh;
    margin: auto auto;
  }
}

.footer-message {
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
  color: var(--color-grey);
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 42px;
}

.instruction-icon-container {
  display: flex;
  margin-bottom: 8px;
  width: 100%;

  img {
    margin: 0 auto;
  }
}

.swiper-pagination {
  bottom: 65px !important;
}

.swiper-pagination-bullet {
  background: rgba(29, 29, 29, 0.3);
  width: 10px !important;
  height: 10px !important;
  transition: all 300ms ease-in;

  &.swiper-pagination-bullet-active {
    background: var(--color-dark);
  }

  &.end {
    display: none;
  }

  &.start {
    background: rgba(255, 255, 255, 0.3);

    &.swiper-pagination-bullet-active {
      background: #fff;
    }
  }
}

.fade-out {
  opacity: 0 !important;
  visibility: hidden !important;
}

.fade-in {
  opacity: 1 !important;
  visibility: visible !important;
}

.initial {
  transition: all 0.3s ease-in;
}

.initial-hidden {
  transition: all 0.3s ease-in;
  opacity: 0;
  visibility: hidden;
}

.privacy-container {
  margin-top: 32px;
  margin-bottom: 42px;
}

.next-icon {
  width: 24px;
  height: 24px;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 14px;
  }
}

.our-banner {
  position: relative;

  img {
    object-fit: cover;
    width: 100%;
    height: auto;
    max-height: 400px;
  }

  p {
    font-family: var(--font-secondary);
    font-size: 32px;
    line-height: 0.84;
    color: white;
    position: absolute;
    bottom: 32px;
    left: 16px;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.article-title {
  font-weight: 700;
  font-size: 30px;
  line-height: 1.2;
  margin: 35px 0;
}

.article-content {
  font-size: 17px;
  line-height: 1.88;
  padding-bottom: 40px;

  span {
    font-weight: 700;
  }
}
